import firebase from "firebase";

import { RecipeCategoryInterface, recipeCategoryConverter } from "@/features/modules/recipe-category/models/RecipeCategory.interface";
import { RecipeCategoryModelInterface } from "../models/RecipeCategoryModel.interface";

import { getReference } from "@/core/modules/helpers";

export const recipeCategoryModel: RecipeCategoryModelInterface = {
  name: "recipeCategory",

  async getRecipeCategories(): Promise<RecipeCategoryInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeCategoryInterface> = await getReference("recipeCategories")
        .withConverter(recipeCategoryConverter)
        .orderBy("order")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeCategoryInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeCategoryInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getRecipeCategory(recipeCategorySlug: string): Promise<RecipeCategoryInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeCategoryInterface> = await getReference("recipeCategories")
        .withConverter(recipeCategoryConverter)
        .where("slugIt", "==", recipeCategorySlug)
        .get();

      if (snapshot.empty) {
        throw new Error(`RecipeCategory #${recipeCategorySlug} not found`);
      }

      return snapshot.docs[0].data() as RecipeCategoryInterface;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
