import { GetterTree } from "vuex";

import { AntaresStateInterface } from "@/core/modules/store/models/AntaresState.interface";
import { LocaleInterface } from "../models/Locale.interface";
import { LocaleStateInterface } from "../models/LocaleState.interface";
import { localeStoreTypes } from "./types";

export const getters: GetterTree<LocaleStateInterface, AntaresStateInterface> = {
  [localeStoreTypes.getters.getSelectedLocale]: (state: LocaleStateInterface): LocaleInterface => {
    // TODO
    const selectedLocale: LocaleInterface = state.locales[0];

    return selectedLocale;
  },
  [localeStoreTypes.getters.getLocales]: (state: LocaleStateInterface): LocaleInterface[] => {
    return state.locales;
  },
};
