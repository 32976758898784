import firebase from "firebase";

import { PageInterface, pageConverter } from "@/features/modules/page/models/Page.interface";
import { PageModelInterface } from "../models/PageModel.interface";

import { getReference } from "@/core/modules/helpers";

export const pageModel: PageModelInterface = {
  name: "page",

  async getPage(pageSlug: string): Promise<PageInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<PageInterface> = await getReference("pages")
        .withConverter(pageConverter)
        .where("slugIt", "==", pageSlug)
        .get();

      if (snapshot.empty) {
        throw new Error(`Page #${pageSlug} not found`);
      }

      return snapshot.docs[0].data() as PageInterface;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
