import { ActionTree } from "vuex";

import { antaresDb } from "@/core/modules/database";
import { AntaresStateInterface } from "@/core/modules/store/models/AntaresState.interface";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { PageInterface } from "../models/Page.interface";
import { PageModelInterface } from "@/features/modules/database/models/PageModel.interface";
import { pageStoreTypes } from "./types";

export const actions: ActionTree<EmptyStateInterface, AntaresStateInterface> = {
  async [pageStoreTypes.actions.getPage](context, pageSlug: string): Promise<PageInterface> {
    try {
      return await antaresDb.getModule<PageModelInterface>("page").getPage(pageSlug);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
