import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/features/layout/NotFound.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/features/layout/Error.vue"),
  },
];
