import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { AntaresStoreModuleInterface } from "@/core/modules/store/models/AntaresStoreModule.interface";

import { actions } from "./actions";

// eslint-disable-next-line prettier/prettier
export const recipeStore: AntaresStoreModuleInterface<EmptyStateInterface> = createStoreModule(
  "recipeState",
  undefined,
  undefined,
  actions
);

export const useRecipeStore = (): AntaresStoreModuleInterface<EmptyStateInterface> => {
  return recipeStore;
};
