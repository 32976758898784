import {
  CollectionInterface,
  createCollection,
  collectionFromFirestore,
  collectionToFirestore,
} from "@/core/modules/database/models/Collection.interface";

import { createFirestoreConverter } from "@/core/modules/helpers";

export interface RecipeCategoryInterface extends CollectionInterface {
  titleEn: string | undefined;
  titleIt: string | undefined;
  slugEn: string | undefined;
  slugIt: string | undefined;
  order: number;
  squareImage: string | undefined;
  wideImage: string | undefined;
}

export const createNewRecipeCategory = (): RecipeCategoryInterface => {
  return createCollection<RecipeCategoryInterface>({
    titleEn: undefined,
    titleIt: undefined,
    slugEn: undefined,
    slugIt: undefined,
    order: 0,
    squareImage: undefined,
    wideImage: undefined,
  });
};

export function recipeCategoryFromFirestore(data: Record<string, unknown>, id?: string): RecipeCategoryInterface {
  return collectionFromFirestore<RecipeCategoryInterface>(
    id != undefined ? id : (data.id as string),
    {
      titleEn: data.titleEn || undefined,
      titleIt: data.titleIt || undefined,
      slugEn: data.slugEn || undefined,
      slugIt: data.slugIt || undefined,
      order: data.order || 0,
      squareImage: data.squareImage || undefined,
      wideImage: data.wideImage || undefined,
    },
    data
  );
}

export function recipeCategoryToFirestore(recipeCategory: RecipeCategoryInterface, id?: string): Record<string, unknown> {
  return collectionToFirestore(
    Object.assign(id != undefined ? { id: id } : {}, {
      titleEn: recipeCategory.titleEn || null,
      titleIt: recipeCategory.titleIt || null,
      slugEn: recipeCategory.slugEn || null,
      slugIt: recipeCategory.slugIt || null,
      order: recipeCategory.order || 0,
      squareImage: recipeCategory.squareImage || null,
      wideImage: recipeCategory.wideImage || null,
    }),
    recipeCategory
  );
}

export const recipeCategoryConverter = createFirestoreConverter(recipeCategoryFromFirestore, recipeCategoryToFirestore);
