import { Composer, createI18n, useI18n } from "vue-i18n";

import { antaresConfig } from "@/core/modules/config";

import { enCoreLabels, enDateTimeFormats, enNumberFormats } from "@/core/locales/en.ts";
import { itCoreLabels, itDateTimeFormats, itNumberFormats } from "@/core/locales/it.ts";

import { enFeaturesLabels } from "@/features/locales/en.ts";
import { itFeaturesLabels } from "@/features/locales/it.ts";

const labels = {
  en: { ...enCoreLabels, ...enFeaturesLabels },
  it: { ...itCoreLabels, ...itFeaturesLabels },
};

const dateTimeFormats = {
  en: enDateTimeFormats,
  it: itDateTimeFormats,
};

const numberFormats = {
  en: enNumberFormats,
  it: itNumberFormats,
};

export const antaresLocale = createI18n({
  legacy: false,
  locale: antaresConfig.defaultLocale,
  fallbackLocale: antaresConfig.fallbackLocale,
  messages: labels,
  datetimeFormats: dateTimeFormats,
  numberFormats: numberFormats,
});

/* TODO
type NumberSchema = {
  currency: {
    style: "currency";
    currencyDisplay: "symbol";
    currency: string;
  };
};
*/

export const useAntaresLocale = (locales?: { "en-US": Record<string, string> }): Composer<unknown, unknown, unknown, unknown, never, unknown> => {
  return useI18n({
    messages: locales,
    useScope: "global",
  });
};
