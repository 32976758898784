export const antaresStoreTypes = {
  getters: {
    isLoading: "IS_LOADING",
  },
  actions: {
    navigateTo: "NAVIGATE_TO",
  },
  mutations: {
    loadingStart: "LOADING_START",
    loadingStop: "LOADING_STOP",
  },
};
