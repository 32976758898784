import { AntaresStateInterface } from "./models/AntaresState.interface";
import { antaresStoreTypes } from "./types";

export const mutations = {
  [antaresStoreTypes.mutations.loadingStart]: (state: AntaresStateInterface): void => {
    state.loading = true;
  },
  [antaresStoreTypes.mutations.loadingStop]: (state: AntaresStateInterface): void => {
    state.loading = false;
  },
};
