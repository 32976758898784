import { ActionTree } from "vuex";

import { AntaresStateInterface } from "@/core/modules/store/models/AntaresState.interface";
import { LocaleInterface } from "../models/Locale.interface";
import { LocaleStateInterface } from "../models/LocaleState.interface";
import { localeStoreTypes } from "./types";

export const actions: ActionTree<LocaleStateInterface, AntaresStateInterface> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [localeStoreTypes.actions.setSelectedLocale](context, locale: LocaleInterface) {
    // TODO
  },
};
