import { antaresConfig } from "@/core/modules/config";

import { DatabaseInterface } from "./models/Database.interface";
import { DatabaseModuleInterface } from "./models/DatabaseModule.interface";

let db: DatabaseInterface;
if (antaresConfig.database.type === "live") {
  db = {
    modules: {},
    getModule: function <T extends DatabaseModuleInterface>(name: string): T {
      return this.modules[name] as T;
    },
  };
} else {
  throw Error("Invalid or undefined config.database.type");
}

export const antaresDb = db;

export const useAntaresDb = (): DatabaseInterface => {
  return antaresDb;
};
