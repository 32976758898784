import { createStore, StoreOptions } from "vuex";

import { AntaresStateInterface } from "./models/AntaresState.interface";
import { AntaresStoreModel } from "./models/AntaresStore.model";

import { initialState } from "./initial-state";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

const storeOptions: StoreOptions<AntaresStateInterface> = {
  state: initialState,
  actions: actions,
  getters: getters,
  mutations: mutations,
};

export const antaresStore: AntaresStoreModel<AntaresStateInterface> = createStore<AntaresStateInterface>(storeOptions);

export const useAntaresStore = (): AntaresStoreModel<AntaresStateInterface> => {
  return antaresStore;
};

export function retrieveModuleGetter<T>(moduleName: string, getterName: string): T {
  return antaresStore.getters[`${moduleName}/${getterName}`];
}

export function dispatchModuleAction<T>(moduleName: string, actionName: string, params?: T): Promise<unknown> {
  return antaresStore.dispatch(`${moduleName}/${actionName}`, params);
}

export function commitModuleMutation<T>(moduleName: string, mutationName: string, params?: T): void {
  antaresStore.commit(`${moduleName}/${mutationName}`, params);
}
