import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { AntaresStoreModuleInterface } from "@/core/modules/store/models/AntaresStoreModule.interface";

import { actions } from "./actions";

// eslint-disable-next-line prettier/prettier
export const recipeCategoryStore: AntaresStoreModuleInterface<EmptyStateInterface> = createStoreModule(
  "recipeCategoryState",
  undefined,
  undefined,
  actions
);

export const useRecipeCategoryStore = (): AntaresStoreModuleInterface<EmptyStateInterface> => {
  return recipeCategoryStore;
};
