import firebase from "firebase/app";

import {
  CollectionInterface,
  createCollection,
  collectionFromFirestore,
  collectionToFirestore,
} from "@/core/modules/database/models/Collection.interface";
import { createFirestoreConverter } from "@/core/modules/helpers";

import { DirectionInterface, directionFromFirestore, directionToFirestore } from "./Direction.interface";
import { IngredientInterface, ingredientFromFirestore, ingredientToFirestore } from "./Ingredient.interface";
import {
  LinkedRecipeCategoryInterface,
  linkedRecipeCategoryFromFirestore,
  linkedRecipeCategoryToFirestore,
} from "@/features/modules/recipe-category/models/LinkedRecipeCategory.interface";

export interface RecipeInterface extends CollectionInterface {
  titleEn: string | undefined;
  titleIt: string | undefined;
  slugEn: string | undefined;
  slugIt: string | undefined;
  excerptEn: string | undefined;
  excerptIt: string | undefined;
  category: LinkedRecipeCategoryInterface | undefined;
  featuredImageSquare: string | undefined;
  featuredImageWide: string | undefined;
  difficulty: string | undefined;
  cookTime: number | undefined;
  prepTime: number | undefined;
  totalTime: number | undefined;
  likesCount: number;
  ingredientsEn: IngredientInterface[];
  ingredientsIt: IngredientInterface[];
  directionsEn: DirectionInterface[];
  directionsIt: DirectionInterface[];
  release: Date;
}

export const createNewRecipe = (): RecipeInterface => {
  return createCollection<RecipeInterface>({
    titleEn: undefined,
    titleIt: undefined,
    slugEn: undefined,
    slugIt: undefined,
    excerptEn: undefined,
    excerptIt: undefined,
    category: undefined,
    featuredImageSquare: undefined,
    featuredImageWide: undefined,
    difficulty: undefined,
    cookTime: undefined,
    prepTime: undefined,
    totalTime: undefined,
    likesCount: 0,
    ingredientsEn: [],
    ingredientsIt: [],
    directionsEn: [],
    directionsIt: [],
    release: new Date(),
  });
};

export function recipeFromFirestore(data: Record<string, unknown>, id?: string): RecipeInterface {
  const ingredientsEn: IngredientInterface[] = [];
  if (data.ingredientsEn != null) {
    (data.ingredientsEn as Array<Record<string, unknown>>).forEach((ingredientEn: Record<string, unknown>) => {
      ingredientsEn.push(ingredientFromFirestore(ingredientEn));
    });
  }
  const ingredientsIt: IngredientInterface[] = [];
  if (data.ingredientsIt != null) {
    (data.ingredientsIt as Array<Record<string, unknown>>).forEach((ingredientIt: Record<string, unknown>) => {
      ingredientsIt.push(ingredientFromFirestore(ingredientIt));
    });
  }

  const directionsEn: DirectionInterface[] = [];
  if (data.directionsEn != null) {
    (data.directionsEn as Array<Record<string, unknown>>).forEach((directionEn: Record<string, unknown>) => {
      directionsEn.push(directionFromFirestore(directionEn));
    });
  }
  const directionsIt: DirectionInterface[] = [];
  if (data.directionsIt != null) {
    (data.directionsIt as Array<Record<string, unknown>>).forEach((directionIt: Record<string, unknown>) => {
      directionsIt.push(directionFromFirestore(directionIt));
    });
  }

  return collectionFromFirestore<RecipeInterface>(
    id != undefined ? id : (data.id as string),
    {
      titleEn: data.titleEn || undefined,
      titleIt: data.titleIt || undefined,
      slugEn: data.slugEn || undefined,
      slugIt: data.slugIt || undefined,
      excerptEn: data.excerptEn || undefined,
      excerptIt: data.excerptIt || undefined,
      category: data.category != null ? linkedRecipeCategoryFromFirestore(data.category as Record<string, unknown>) : undefined,
      featuredImageSquare: data.featuredImageSquare || undefined,
      featuredImageWide: data.featuredImageWide || undefined,
      difficulty: data.difficulty || undefined,
      cookTime: data.cookTime || undefined,
      prepTime: data.prepTime || undefined,
      totalTime: data.totalTime || undefined,
      likesCount: data.likesCount || 0,
      ingredientsEn: ingredientsEn,
      ingredientsIt: ingredientsIt,
      directionsEn: directionsEn,
      directionsIt: directionsIt,
      release: data.release != null ? (data.release as firebase.firestore.Timestamp).toDate() : new Date(),
    },
    data
  );
}

export function recipeToFirestore(recipe: RecipeInterface, id?: string): Record<string, unknown> {
  const ingredientsEn: Record<string, unknown>[] = [];
  recipe.ingredientsEn.forEach((ingredientEn: IngredientInterface) => {
    ingredientsEn.push(ingredientToFirestore(ingredientEn));
  });
  const ingredientsIt: Record<string, unknown>[] = [];
  recipe.ingredientsIt.forEach((ingredientIt: IngredientInterface) => {
    ingredientsIt.push(ingredientToFirestore(ingredientIt));
  });

  const directionsEn: Record<string, unknown>[] = [];
  recipe.directionsEn.forEach((directionEn: DirectionInterface) => {
    directionsEn.push(directionToFirestore(directionEn));
  });
  const directionsIt: Record<string, unknown>[] = [];
  recipe.directionsIt.forEach((directionIt: DirectionInterface) => {
    directionsIt.push(directionToFirestore(directionIt));
  });

  return collectionToFirestore(
    Object.assign(id != undefined ? { id: id } : {}, {
      titleEn: recipe.titleEn || null,
      titleIt: recipe.titleIt || null,
      slugEn: recipe.slugEn || null,
      slugIt: recipe.slugIt || null,
      excerptEn: recipe.excerptEn || null,
      excerptIt: recipe.excerptIt || null,
      category: recipe.category != undefined ? linkedRecipeCategoryToFirestore(recipe.category) : null,
      featuredImageSquare: recipe.featuredImageSquare || null,
      featuredImageWide: recipe.featuredImageWide || null,
      difficulty: recipe.difficulty || null,
      cookTime: recipe.cookTime || null,
      prepTime: recipe.prepTime || null,
      totalTime: recipe.totalTime || null,
      likesCount: recipe.likesCount || 0,
      ingredientsEn: ingredientsEn,
      ingredientsIt: ingredientsIt,
      directionsEn: directionsEn,
      directionsIt: directionsIt,
      release: recipe.release != undefined ? firebase.firestore.Timestamp.fromDate(recipe.release) : firebase.firestore.Timestamp.now(),
    }),
    recipe
  );
}

export const recipeConverter = createFirestoreConverter(recipeFromFirestore, recipeToFirestore);
