import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import { antaresConfig } from "@/core/modules/config";

const firebaseConfig = {
  apiKey: antaresConfig.firebase.apiKey,
  authDomain: antaresConfig.firebase.authDomain,
  databaseURL: antaresConfig.firebase.databaseURL,
  projectId: antaresConfig.firebase.projectId,
  storageBucket: antaresConfig.firebase.storageBucket,
  appId: antaresConfig.firebase.appId,
};

firebase.initializeApp(firebaseConfig);
firebase.auth();
firebase.firestore();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const firebaseAuth = firebase.auth();
export const firebaseFirestore = firebase.firestore();
export const firebaseFunctions = firebase.functions();
export const firebaseStorage = firebase.storage();
