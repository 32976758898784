import { RouteRecordRaw } from "vue-router";

import { antaresConfig } from "@/core/modules/config";
import { antaresRouter } from "@/core/modules/router";

export function registerRoutes(routes: Array<RouteRecordRaw>): void {
  routes.forEach((route: RouteRecordRaw) => antaresRouter.addRoute(route));
}

export function resetLocalStorageIfNeeded(): void {
  if (!localStorage.getItem("antaresVersion")) {
    localStorage.clear();
    localStorage.setItem("antaresVersion", antaresConfig.version);
  }

  const currentAppVersion: string = localStorage.getItem("antaresVersion") as string;

  console.log("Antares Version", currentAppVersion);

  if (currentAppVersion !== antaresConfig.version) {
    localStorage.clear();
    localStorage.setItem("antaresVersion", antaresConfig.version);
  }
}

export function goToErrorPage(error: string): void {
  localStorage.setItem("lastError", error);
  antaresRouter.push("/error");
}
