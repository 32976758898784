import firebase from "firebase/app";

export interface CollectionInterface {
  id: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  toString: () => string;
}

export const newCollection: CollectionInterface = {
  id: "new",
  createdAt: new Date(),
  createdBy: "",
  updatedAt: new Date(),
  updatedBy: "",
  toString: function (): string {
    return this.id;
  },
};

export function createCollection<T>(data: Record<string, unknown>): T {
  return Object.assign(data, newCollection) as T;
}

export function collectionFromFirestore<T>(id: string, data: Record<string, unknown>, collection: Record<string, unknown>): T {
  return Object.assign(data, {
    id: id,
    createdAt: (collection.createdAt as firebase.firestore.Timestamp).toDate(),
    createdBy: collection.createdBy,
    updatedAt: (collection.updatedAt as firebase.firestore.Timestamp).toDate(),
    updatedBy: collection.updatedBy,
    toString: function (): string {
      return this.id;
    },
  }) as T;
}

export function collectionToFirestore(data: Record<string, unknown>, collection: CollectionInterface): Record<string, unknown> {
  return Object.assign(data, {
    createdAt: firebase.firestore.Timestamp.fromDate(collection.createdAt),
    createdBy: collection.createdBy,
    updatedAt: firebase.firestore.Timestamp.fromDate(collection.updatedAt),
    updatedBy: collection.updatedBy,
  });
}
