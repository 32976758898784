export interface LinkedRecipeCategoryInterface {
  id: string;
  titleEn: string | undefined;
  titleIt: string | undefined;
  slugEn: string | undefined;
  slugIt: string | undefined;
  toString: () => string;
}

export const createNewLinkedRecipeCategory = (): LinkedRecipeCategoryInterface => {
  return {
    id: "new",
    titleEn: undefined,
    titleIt: undefined,
    slugEn: undefined,
    slugIt: undefined,
  };
};

export function linkedRecipeCategoryFromFirestore(data: Record<string, unknown>): LinkedRecipeCategoryInterface {
  return {
    id: data.id as string,
    titleEn: data.titleEn as string,
    titleIt: data.titleIt as string,
    slugEn: data.slugEn as string,
    slugIt: data.slugIt as string,
    toString: function (): string {
      return this.id;
    },
  };
}

export function linkedRecipeCategoryToFirestore(data: LinkedRecipeCategoryInterface): Record<string, unknown> {
  return {
    id: data.id,
    titleEn: data.titleEn || null,
    titleIt: data.titleIt || null,
    slugEn: data.slugEn || null,
    slugIt: data.slugIt || null,
  };
}
