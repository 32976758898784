import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { RecipeCategoryInterface } from "../models/RecipeCategory.interface";
import { RecipeCategoryModelInterface } from "@/features/modules/database/models/RecipeCategoryModel.interface";
import { recipeCategoryStoreTypes } from "./types";
import { antaresDb } from "@/core/modules/database";
import { AntaresStateInterface } from "@/core/modules/store/models/AntaresState.interface";

export const actions: ActionTree<EmptyStateInterface, AntaresStateInterface> = {
  async [recipeCategoryStoreTypes.actions.getRecipeCategories](): Promise<RecipeCategoryInterface[]> {
    try {
      return await antaresDb.getModule<RecipeCategoryModelInterface>("recipeCategory").getRecipeCategories();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeCategoryStoreTypes.actions.getRecipeCategory](context, recipeCategorySlug: string): Promise<RecipeCategoryInterface> {
    try {
      return await antaresDb.getModule<RecipeCategoryModelInterface>("recipeCategory").getRecipeCategory(recipeCategorySlug);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
