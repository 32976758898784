import { createApp, h } from "vue";

import { antaresLocale } from "@/core/modules/locale";
import { antaresRouter } from "@/core/modules/router";
import { antaresStore } from "@/core/modules/store";
import { registerDatabaseModule, registerRoutes, registerStoreModule, resetLocalStorageIfNeeded } from "@/core/modules/helpers";

import { contactRoutes } from "@/features/modules/contact/router";
import { homeRoutes } from "@/features/modules/home/router";
import { pageRoutes } from "@/features/modules/page/router";
import { recipeCategoryRoutes } from "@/features/modules/recipe-category/router";
import { recipeRoutes } from "@/features/modules/recipe/router";

import { useLocaleStore } from "@/core/modules/locale/store";
import { usePageStore } from "@/features/modules/page/store";
import { useRecipeCategoryStore } from "@/features/modules/recipe-category/store";
import { useRecipeStore } from "@/features/modules/recipe/store";

import { pageModel } from "@/features/modules/database/firestore-db/Page.model";
import { recipeCategoryModel } from "@/features/modules/database/firestore-db/RecipeCategory.model";
import { recipeModel } from "@/features/modules/database/firestore-db/Recipe.model";

import Base from "@/features/layout/Base.vue";

/////////////////////////////////////////////////////////////
//                       create app                        //
/////////////////////////////////////////////////////////////
export const antaresApp = createApp({
  computed: {
    ViewComponent() {
      switch (this.$route.name) {
        case "NotFound":
          return Base;
        case "Error":
          return Base;
        default:
          return Base;
      }
    },
  },
  render() {
    return h(this.ViewComponent);
  },
});

/////////////////////////////////////////////////////////////
//                     register routes                     //
/////////////////////////////////////////////////////////////
registerRoutes(contactRoutes);
registerRoutes(homeRoutes);
registerRoutes(pageRoutes);
registerRoutes(recipeCategoryRoutes);
registerRoutes(recipeRoutes);

/////////////////////////////////////////////////////////////
//                register database modules                //
/////////////////////////////////////////////////////////////
registerDatabaseModule(pageModel);
registerDatabaseModule(recipeCategoryModel);
registerDatabaseModule(recipeModel);

/////////////////////////////////////////////////////////////
//                 register store modules                  //
/////////////////////////////////////////////////////////////
registerStoreModule(useLocaleStore());

registerStoreModule(usePageStore());
registerStoreModule(useRecipeCategoryStore());
registerStoreModule(useRecipeStore());

/////////////////////////////////////////////////////////////
//                          boot                           //
/////////////////////////////////////////////////////////////
async function boot() {
  try {
    if (antaresApp.config.globalProperties.mounted !== true) {
      antaresApp.use(antaresLocale);
      antaresApp.use(antaresRouter);
      antaresApp.use(antaresStore);

      antaresApp.mount("#app");
      antaresApp.config.globalProperties.mounted = true;

      resetLocalStorageIfNeeded();
    }
  } catch {
    console.log("Unable to boot Antares");
  }
}

boot();
