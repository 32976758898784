import { RouteRecordRaw } from "vue-router";

export const homeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/it/",
  },
  {
    path: "/it/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
];
