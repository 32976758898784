import { createRouter, createWebHistory, Router } from "vue-router";

import { antaresStoreTypes } from "@/core/modules/store/types";
import { routes } from "./routes";
import { useAntaresStore } from "@/core/modules/store";

export const antaresRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

antaresRouter.beforeEach((to, from, next) => {
  const antaresStore = useAntaresStore();

  window.scrollTo(0, 0);

  if (from.path != to.path) {
    antaresStore.commit(antaresStoreTypes.mutations.loadingStart);
  }

  next();
});

export const useAntaresRouter = (): Router => {
  return antaresRouter;
};
