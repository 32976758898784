import firebase from "firebase/app";

import { RecipeInterface, recipeConverter } from "@/features/modules/recipe/models/Recipe.interface";
import { RecipeModelInterface } from "../models/RecipeModel.interface";

import { getReference } from "@/core/modules/helpers";

export const recipeModel: RecipeModelInterface = {
  name: "recipe",

  async getLatestRecipes(): Promise<RecipeInterface[]> {
    try {
      const now = firebase.firestore.Timestamp.now();

      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .where("release", "<", now)
        .orderBy("release", "desc")
        .limit(6)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getRecipesByCategory(recipeCategoryId: string): Promise<RecipeInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .where("category.id", "==", recipeCategoryId)
        .orderBy("release", "desc")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getRecipe(recipeSlug: string): Promise<RecipeInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .where("slugIt", "==", recipeSlug)
        .get();

      if (snapshot.empty) {
        throw new Error(`Recipe #${recipeSlug} not found`);
      }

      return snapshot.docs[0].data() as RecipeInterface;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
