import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { antaresDb } from "@/core/modules/database";
import { AntaresStateInterface } from "@/core/modules/store/models/AntaresState.interface";
import { RecipeInterface } from "../models/Recipe.interface";
import { RecipeModelInterface } from "@/features/modules/database/models/RecipeModel.interface";
import { recipeStoreTypes } from "./types";

export const actions: ActionTree<EmptyStateInterface, AntaresStateInterface> = {
  async [recipeStoreTypes.actions.getLatestRecipes](): Promise<RecipeInterface[]> {
    try {
      return await antaresDb.getModule<RecipeModelInterface>("recipe").getLatestRecipes();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.getRecipesByCategory](context, recipeCategoryId: string): Promise<RecipeInterface[]> {
    try {
      return await antaresDb.getModule<RecipeModelInterface>("recipe").getRecipesByCategory(recipeCategoryId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.getRecipe](context, recipeSlug: string): Promise<RecipeInterface> {
    try {
      return await antaresDb.getModule<RecipeModelInterface>("recipe").getRecipe(recipeSlug);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
