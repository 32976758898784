import { uniqueId } from "@/core/plugins/unique-id";

export interface IngredientInterface {
  id: string;
  type: string | undefined;
  value: string | undefined;
  toString: () => string;
}

export const createNewIngredient = (): IngredientInterface => {
  return {
    id: uniqueId(),
    type: "ingredient",
    value: "Scrivi...",
  };
};

export function ingredientFromFirestore(data: Record<string, unknown>): IngredientInterface {
  return {
    id: uniqueId(),
    type: data.type as string,
    value: data.value as string,
    toString: function (): string {
      return this.id;
    },
  };
}

export function ingredientToFirestore(data: IngredientInterface): Record<string, unknown> {
  return {
    type: data.type || null,
    value: data.value || null,
  };
}
