
import { defineComponent } from "vue";

import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default defineComponent({
  name: "Base",
  components: {
    Header,
    Footer,
  },
});
