import { ActionTree } from "vuex";
import { NavigationFailure } from "vue-router";

import { antaresRouter } from "@/core/modules/router";
import { AntaresStateInterface } from "@/core/modules/store/models/AntaresState.interface";
import { antaresStoreTypes } from "./types";

export const actions: ActionTree<AntaresStateInterface, AntaresStateInterface> = {
  async [antaresStoreTypes.actions.navigateTo](context, route: string): Promise<void | NavigationFailure | undefined> {
    return await antaresRouter.push(route);
  },
};
