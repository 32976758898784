import { ActionTree, GetterTree, MutationTree } from "vuex";

import { AntaresStateInterface } from "@/core/modules/store/models/AntaresState.interface";
import { AntaresStoreModuleInterface } from "@/core/modules/store/models/AntaresStoreModule.interface";
import { useAntaresStore, commitModuleMutation, dispatchModuleAction, retrieveModuleGetter } from "@/core/modules/store";

export function createStoreModule<T>(
  namespace: string,
  initialState?: T,
  getters?: GetterTree<T, AntaresStateInterface>,
  actions?: ActionTree<T, AntaresStateInterface>,
  mutations?: MutationTree<T>
): AntaresStoreModuleInterface<T> {
  return {
    namespaced: true,
    namespace: namespace,
    get state(): T {
      return initialState || ({ empty: "empty" } as unknown as T);
    },
    getters: getters,
    actions: actions,
    mutations: mutations,
    getter<T>(getterName: string): T {
      return retrieveModuleGetter<T>(namespace, getterName);
    },
    action<T>(actionName: string, params?: T): Promise<unknown> {
      return dispatchModuleAction(namespace, actionName, params);
    },
    commit<T>(mutationName: string, params?: T): void {
      commitModuleMutation(namespace, mutationName, params);
    },
  };
}

export function registerStoreModule<T>(storeModule: AntaresStoreModuleInterface<T>): void {
  const antaresStore = useAntaresStore();
  if (!antaresStore.hasModule(storeModule.namespace)) {
    antaresStore.registerModule(storeModule.namespace, storeModule);
  }
}
