import firebase from "firebase";

import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { firebaseFirestore } from "@/core/plugins/firebase";
import { antaresDb } from "@/core/modules/database";

export function getReference(collection: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  return firebaseFirestore.collection(collection);
}

export function createFirestoreConverter<T>(
  dataFromFirestore: (data: Record<string, unknown>, id?: string) => T,
  dataToFirestore: (collection: T, id?: string) => Record<string, unknown>
): firebase.firestore.FirestoreDataConverter<T> {
  return {
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      options: firebase.firestore.SnapshotOptions | undefined
    ): T {
      const data = snapshot.data(options);
      return dataFromFirestore(data, snapshot.id);
    },
    toFirestore: function (collection: T): Record<string, unknown> {
      return dataToFirestore(collection);
    },
  };
}

export function registerDatabaseModule(module: DatabaseModuleInterface): void {
  if (!(module.name in antaresDb.modules)) {
    antaresDb.modules[module.name] = module;
  }
}
