import { RouteRecordRaw } from "vue-router";

export const recipeCategoryRoutes: Array<RouteRecordRaw> = [
  {
    path: "/it/categorie/",
    name: "RecipeCategories",
    component: () => import("../views/RecipeCategories.vue"),
  },
  {
    path: "/it/categorie/:slug",
    name: "RecipeCategory",
    component: () => import("../views/RecipeCategory.vue"),
  },
];
