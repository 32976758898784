import { AntaresStoreModuleInterface } from "@/core/modules/store/models/AntaresStoreModule.interface";
import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";

import { actions } from "./actions";

// eslint-disable-next-line prettier/prettier
export const pageStore: AntaresStoreModuleInterface<EmptyStateInterface> = createStoreModule(
  "pageState",
  undefined,
  undefined,
  actions,
);

export const usePageStore = (): AntaresStoreModuleInterface<EmptyStateInterface> => {
  return pageStore;
};
