import {
  CollectionInterface,
  createCollection,
  collectionFromFirestore,
  collectionToFirestore,
} from "@/core/modules/database/models/Collection.interface";

import { createFirestoreConverter } from "@/core/modules/helpers";

export interface PageInterface extends CollectionInterface {
  titleEn: string | undefined;
  titleIt: string | undefined;
  slugEn: string | undefined;
  slugIt: string | undefined;
  contentEn: string | undefined;
  contentIt: string | undefined;
}

export const createNewPage = (): PageInterface => {
  return createCollection<PageInterface>({
    titleEn: undefined,
    titleIt: undefined,
    slugEn: undefined,
    slugIt: undefined,
    contentEn: undefined,
    contentIt: undefined,
  });
};

export function pageFromFirestore(data: Record<string, unknown>, id?: string): PageInterface {
  return collectionFromFirestore<PageInterface>(
    id != undefined ? id : (data.id as string),
    {
      titleEn: data.titleEn || undefined,
      titleIt: data.titleIt || undefined,
      slugEn: data.slugEn || undefined,
      slugIt: data.slugIt || undefined,
      contentEn: data.contentEn || undefined,
      contentIt: data.contentIt || undefined,
    },
    data
  );
}

export function pageToFirestore(page: PageInterface, id?: string): Record<string, unknown> {
  return collectionToFirestore(
    Object.assign(id != undefined ? { id: id } : {}, {
      titleEn: page.titleEn || null,
      titleIt: page.titleIt || null,
      slugEn: page.slugEn || null,
      slugIt: page.slugIt || null,
      contentEn: page.contentEn || null,
      contentIt: page.contentIt || null,
    }),
    page
  );
}

export const pageConverter = createFirestoreConverter(pageFromFirestore, pageToFirestore);
