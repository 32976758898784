import { uniqueId } from "@/core/plugins/unique-id";

export interface DirectionInterface {
  id: string;
  type: string | undefined;
  value: string | undefined;
  toString: () => string;
}

export const createNewDirection = (): DirectionInterface => {
  return {
    id: uniqueId(),
    type: "direction",
    value: "Scrivi...",
  };
};

export function directionFromFirestore(data: Record<string, unknown>): DirectionInterface {
  return {
    id: uniqueId(),
    type: data.type as string,
    value: data.value as string,
    toString: function (): string {
      return this.id;
    },
  };
}

export function directionToFirestore(data: DirectionInterface): Record<string, unknown> {
  return {
    type: data.type || null,
    value: data.value || null,
  };
}
